import React,{useEffect} from "react";

function Testimonials(){
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const reviews=[
        {
            id:1,
            review: "We really fell in love with the quality that Marine world zone assured and the multiple options that they were to offer. The designers were able to provide us with a lot of smart options.",
            name:"amit srivastava - Amit law institute- lko ",
            location: "bangalore",
            rating:5
        },
        {
            id:2,
            review: "We’re really happy with the materials that were used and the timeline for the project. The reaction we got from our friends when they first saw our place was priceless.",
            name:"shubh Chatturvedi - Xpertise education pvt Ltd -lko ",
            location: "lucknow",
            rating:5
        },
        {
            id:3,
            review: "Our designer took us through our options in extraordinary detail and was extremely helpful in setting up our home the way we wanted.",
            name:"paritosh Shreshth - Distt judge - Saharanpur",
            location: "bangalore",
            rating:5
        },
        {
            id:4,
            review: "A place where I can find peace. A home. That’s exactly what Marine world zone has helped me with. I’m very happy with the outcome.",
            name:"Abha an rajeev - Jankipuram , Lko",
            location: "bangalore",
            rating:5
        },
        {
            id:5,
            review: "Given the restrictions with COVID, we were quite skeptical if they would be able to deliver within the promised time. They delivered in just 25 to 27 days.",
            name:"mr somesh - Ashiyana ,Lko ",
            location: "bangalore",
            rating:5
        },
        {
            id:6,
            review: "Home” is somewhere I can be completely disconnected from work, relax, unwind and relieve my tension. Marine world zone helped create the perfect space for that.",
            name:"Vikas Obrai - Vipul green , Gurgaow ",
            location: "lucknow",
            rating:5
        },
        {
            id:7,
            review: "When you design your house, you tend to do everything that is right for you. Marine world zone has actually turned our dream into reality.",
            name:"Vishal Bhargav - Vasundhara , Ghaziabad ",
            location: "lucknow",
            rating:5
        },
        {
            id:8,
            review: "Whatever time, energy and money we have spent, at the end of the day, with Marine world zone, we felt that we were fully satisfied with the ultimate outcome.",
            name:" Dr Bhajpai - Baba hospital Lko ",
            location: "lucknow",
            rating:5
        },
        {
            id:9,
            review: "We wanted our dream home to be unique, spacious, and elegant. And we think Marine world zone has captured that accurately.",
            name:" Dr Bhatia - Chandra eye hospital - Lko",
            location: "lucknow",
            rating:5
        }
    ]

    const render_reviews=(data)=>{
        const renderRating=(data)=>{
            var rt_img=[]
            for(let i=0; i< data;i++){
                rt_img.push({})
            }
            const renderReviewImg= rt_img.map(()=>{
                return(
                    <img className="star-img" src="images/testimonials/star.png" alt="star" />
                );
            })
            return(
                <div>
                    <div className="review-flex-div-star">{renderReviewImg}</div>
                </div>
            );
        }
        const renderBox= data.map((data)=>{
            return(
                <div className="col-12 col-sm-6 mt-5">
                    <div className="review-box">
                        {renderRating(data.rating)}
                        <p className="mt-3 text-left">{data.review}</p>
                        <strong className="">--{data.name}</strong>
                    </div>
                </div>
            );
        })
        return(
            <div className="row mb-5">
                {renderBox}
            </div>
        );
    }

    return(
        <div>
            <div className="portfolio-bg overflow-hidden">
                <div className="review-test-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 about-top-margin">
                            <div><p className="about-top-txt-1"></p><br/></div>
                            <div><p className="about-top-txt-2">Testimonials</p><br/></div>
                            <div><strong className="about-top-txt-3">Client's Feedback</strong><br/></div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="section-2-bg">
                <div className="container">
                    {render_reviews(reviews)}
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
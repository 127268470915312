import React,{Component} from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";


class Home extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div>
                <div className="home-bg-img overflow-hidden">
                    <div className="test-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div><p className="sect-1-txt-1 fl-left">intirior that focused on luxury & practicality.</p><br/></div>
                                <div><p className="sect-1-txt-2 fl-left">We Turn Dreams Into Reality</p><br/></div>
                                <div><strong className="sect-1-txt-3 fl-left"><span className="fl-left">We create designs in a collaborative environment, enabling us to deliver the client's ultimate vision. Our designs are current yet still possess a timeless style, generating broad market appeal. Our reputation for outstanding service and our innovative design style create an extremely successful partnership with our clients.</span></strong><br/></div>
                            </div>
                            <div className="col-12 col-lg-6 abs">
                                <img className="nav-img-1" src="home_img_1.jpg"/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                {/*Section 2 */}
                <div className="section-2-bg">
                <div className="container">
                    <div className="row relative overflow-hidden">
                        <div className="col-12 section-2-div-txt-bg"><p className="section-2-txt-bg">MARINE WORLD ZONE</p></div>
                        <div className="row text-center mb-5 absolute">
                            <div className="col-lg-2"></div>
                            <div className="col-12 section-2-div-hd">
                                <h4 className="section-2-txt-hd">INTERIOR • DESIGN REMODELING • HOME FURNISHINGS • COLOR CONSULTATIONS</h4>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h4 className="text-left section-2-txt-2-hd">WHAT WE DO</h4>
                            <p className="text-left section-2-txt-2 mt-4">We are a company focused on designing whole-house solutions that integrate modern aesthetics with tailored living experiences. We subscribe to clean lines, flowing living spaces, and design materials that showcase your space to its fullest.</p>
                            <Link to='/about'><Button className="section-2-btn">More About Us<span className="fa fa-arrow-right fa-btn"></span></Button></Link>
                        </div>
                        <div className="col-12 col-lg-5">
                            <img className="section-2-img" src="images/section_2/img1.jpg" alt="Home designs" />
                            <p className="section-2-img-txt mt-3"></p>
                        </div>
                    </div>
                </div>
                </div>
                <div className="section-3-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-1 col-lg-7"></div>
                            <div className="col-12 col-sm-10 col-lg-5">
                                <div className="section-3-box">
                                    <p className="section-3-txt-hd">Our Mission</p>
                                    <div className="section-1-txt-1-div"><p className="section-3-txt-1">MARINE WORLD ZONE offers an exclusive,highly -personalised intirior an Exterior design services for a discerning clientele that aspire to elevate the standards of their living and work spaces. Founded in 2017 by founder an lead - designer Amit Kumar and a Strategy Planner Sandeep kumar , the firm was setup with the vision of delivering and expertise to its residential and commercial Cleints. 
By working exclusively with a team of specifically trained and accredited professionals who have been carefully selected for each individual project , the firm designs interiors which allow Cleints to fully express what they are about hold meaning to them ..</p></div>
                                    <Link to='/contact'><Button className="section-3-btn">Contact Us<span className="fa fa-arrow-right fa-btn"></span></Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                {/* section-4 */}
                <div className="section-2-bg">
                    <div className="container">
                        <div className="row relative overflow-hidden">
                            <div className="col-12 section-4-div-txt-bg"><p className="section-4-txt-bg">MARINE WORLD<br/>ZONE</p></div>
                            <div className="row text-center mb-5 absolute">
                                <div className="col-lg-2"></div>
                                <div className="col-12 col-lg-7 section-4-div-hd">
                                    <p className="section-4-txt-1">Every space count</p>
                                    <h2 className="section-4-txt-2">Why choose us</h2>
                                </div>
                                <div className="col-lg-3"></div>
                            </div>
                        </div>
                        <div className="row relative mt-3 section-4-pd-b">
                            <div className="col-12 col-lg-4 tp-0 mt-5 mb-3">
                                <div className="row">
                                    <div className="col-12">
                                    <img className="section-4-img" src='images/section_4/img1.png' />
                                    </div>
                                </div>
                                <h3>Commercial Interior Design</h3>
                                <p>Our group of experienced architects and engineers will help you step by step in getting the most professional look and feel for your commercial space. We help you building the best commercial interiors from the initial planning phase only.</p>
                            </div>
                            <div className="col-12 col-lg-4 tp-0  mt-5 mb-3">
                                <div className="row">
                                    <div className="col-12">
                                    <img className="section-4-img" src='images/section_4/img2.png' />
                                    </div>
                                </div>
                                <h3>Home Interior Space</h3>
                                <p>We all have been in that place before, and all we need is good guidance and understanding different intricacies involved in selecting the right home interior designers and decorators. We help you in making the right decision towards your goal of fulfilling your dream home.</p>
                            </div>
                            <div className="col-12 col-lg-4 tp-0 mt-5 mb-3">
                                <div className="row">
                                    <div className="col-12">
                                    <img className="section-4-img" src='images/section_4/img3.png' />
                                    </div>
                                </div>                                <h3>Small Offices </h3>
                                <p>No matter the size of your workplace, transforming your office can be a daunting experience. Whether refurbishing or relocating, our teams help you create an environment that works for your business.</p>
                            </div>
                            <div className="col-12 col-lg-4 mt-5 mb-3">
                                <div className="row">
                                    <div className="col-12">
                                    <img className="section-4-img" src='images/section_4/img4.png' />
                                    </div>
                                </div>                                <h3>Renovating Space</h3>
                                <p>The term renovation work refers to making changes or improvements to a property. It can include painting and wallpapering to gutting and reconfiguring an entire room. There are several reasons to undertake renovation work, such as increasing the value of a property before selling it, making changes to suit the needs of a new tenant, or simply freshening up a space that has become outdated. No matter the reason for undertaking renovation work, the end goal is always the same: to improve the property somehow.</p>
                            </div>
                            <div className="col-12 col-lg-4 mt-5 mb-3">
                                <div className="row">
                                    <div className="col-12">
                                    <img className="section-4-img" src='images/section_4/img5.png' />
                                    </div>
                                </div>                                <h3>Free Consultation</h3>
                                <p>We provide free interior design consultant for consultation on interior designs and a free quote. Contact us now for our services</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
import React,{Component} from "react";
import { Button,Form,FormGroup, Row,Col,Label, Input, FormFeedback } from "reactstrap";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios";



const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class ContactUs extends Component{
    constructor(props){
        super(props);
        this.state = {
            username:"",
            email:"",
            phonenum:"",
            prpname:"",
            invalidphone:false,
            invalidemail:false,
            validphone:false,
            validemail:false

        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        const onPhoneChange=(e) =>{
            this.setState({
                phonenum:e.target.value
            })
            if(e.target.value.length <10 || e.target.value.length >10 || !isNumber(e.target.value)){
                this.setState({
                    invalidphone:true,
                    validphone:false
                })
            }
            else{
                this.setState({
                    invalidphone:false,
                    validphone:true
                })
            }
        }
        const onEmailChange=(e)=>{
            this.setState({
                email:e.target.value
            })
            if(!validEmail(e.target.value)){
                this.setState({
                    invalidemail:true,
                    validemail:false
                })
            }
            else{
                this.setState({
                    validemail:true,
                    invalidemail:false
                })
            }
        }
        const handleSubmit=(event)=>{
            if(this.state.phonenum.length != 10 || !isNumber(this.state.phonenum)){
                this.setState({
                    invalidphone:true
                })
            }
            if(!validEmail(this.state.email)){
                this.setState({
                    invalidemail:true
                })
            }
            if(this.state.phonenum.length === 10 && validEmail(this.state.email) && isNumber(this.state.phonenum)){
                this.setState({
                    invalidphone:false,
                    invalidemail:false,
                    validphone:false,
                    validemail:false,
                    username:"",
                    email:"",
                    prpname:"",
                    phonenum:""
                })
                axios.post('http://15.207.107.128:6300/public/contactUs',
                {
                    Name:this.state.username,
                    Mobile:this.state.phonenum,
                    Email:this.state.email,
                    ProperyDetail:this.state.prpname
                })
                .then((res)=>{
                    if(res.status===200){
                        NotificationManager.success(res.data.Message);
                    }
                    else{
                        console.log("else occured")
                        NotificationManager.warning("Error ",res.status,": ",res.statusText);
                    }
                })
                .catch((error)=>{
                    console.log("error occured")
                    NotificationManager.error(error.message);                
                })
            }
            event.preventDefault();
        }
        return(
            <div>
                <NotificationContainer />
                <div className="contact-bg overflow-hidden">
                    <div className="contact-test-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 about-top-margin">
                                <div><p className="about-top-txt-1">Get in touch</p><br/></div>
                                <div><p className="about-top-txt-2">Contact Us</p><br/></div>
                                <div><strong className="about-top-txt-3">We Love to hear from you</strong><br/></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="section-2-bg">
                    <div className="container">
                        <div className="row contact-1-pd-1">
                            <div className="col-12 col-lg-4 mt-5">
                                <img className="contact-img" src="images/ContactUs/img1.jpg" />
                            </div>
                            <div className="col-12 col-lg-8 mt-5">
                                <div className="row">
                                    <div className="col-12 col-sm-6 text-left">
                                        <h2 className="contact-txt-3">Let's discuss About your Space's</h2>
                                        <p className="contact-txt-1">Whether you’re a home owner, tenant, an architect or builder looking for interior design input, we’d love to hear from you! Send us a message or contact us directly and we’ll get back to you as soon as possible.</p>
                                        <div>
                                            <p className="contact-txt-2-ad"><img className="contact-arrow" src="images/ContactUs/arrow.png" /><span className="contact-text-2">Free Consultation</span></p>
                                            <p className="contact-txt-2-ad"><img className="contact-arrow" src="images/ContactUs/arrow.png" /><span className="contact-text-2">Budget Quotations</span></p>
                                            <p className="contact-txt-2-ad"><img className="contact-arrow" src="images/ContactUs/arrow.png" /><span className="contact-text-2">Latest Technologies</span></p>
                                            <p className="contact-txt-2-ad"><img className="contact-arrow" src="images/ContactUs/arrow.png" /><span className="contact-text-2">Eco Friendly Constructions</span></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 contact-form-div">
                                        <Form>
                                            <FormGroup>
                                            <Row>
                                                <Col md={12}>
                                                <Input invalid={false} onChange={(e)=> this.setState({username:e.target.value})} type="text" id="name" name="name" placeholder="Name"
                        />
                                                <FormFeedback invalid>Invalid Input</FormFeedback>
                                                </Col>
                                            </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row>
                                                    <Col md={12}>
                                                    <Input valid={this.state.validphone} invalid={this.state.invalidphone} onChange={(e)=> onPhoneChange(e)} type="text" id="phonenum" name="phonenum" placeholder="Phone Number"
                            />
                                                    <FormFeedback invalid >Enter Valid phone number</FormFeedback>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row>
                                                    <Col md={12}>
                                                    <Input valid={this.state.validemail} invalid={this.state.invalidemail} onChange={(e)=> onEmailChange(e)} type="text" id="email" name="email" placeholder="Email"/>
                                                    <FormFeedback invalid>Enter Valid Email</FormFeedback>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row>
                                                <Col md={12}>
                                                <Input invalid={false} onChange={(e)=> this.setState({prpname:e.target.value})} type="text" id="prpname" name="prpname" placeholder="Property Name"
                        />
                                                <FormFeedback>Invalid Input</FormFeedback>
                                                </Col>
                                                </Row>
                                            </FormGroup>
                                        </Form>
                                        <div>
                                            <Button onClick={handleSubmit} className="contact-btn">Submit<span className="fa fa-arrow-right fa-btn"></span></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* About section-2 */}

                        <div className="row mt-5">
                            <div className="col-12 col-sm-6 text-left mt-5">
                                <h2>Contact Details</h2>
                                <p className="contact-section-2-text-1 mt-4">We are comprehensive interior design company managing a wide spectrum of projects, including interior architecture, renovations and styling in residences, offices or other commercial spaces. Contact us today to start your journey</p>
                            </div>
                            <div className="col-12 col-sm-6 mt-5">
                                <div><a href="#"><span><img className="contact-social-img" src="images/AboutUs/facebook.png" /></span></a><a href="#"><span><img className="contact-social-img" src="images/AboutUs/instagram.png" /></span></a></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 text-left">
                                <div className="row contact-section-2-row-bg">
                                    <div className="col-2">
                                        <img className="contact-section-2-img" src="images/ContactUs/location.png" />
                                    </div>
                                    <div className="col-10">
                                        <h4>Address</h4>
                                        <address>
                                            plot no 29, Hardoi road , DURGAGANJ, Lucknow . 227101
                                        </address>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 text-left">
                                <div className="row contact-section-2-row-bg">
                                    <div className="col-2">
                                        <img className="contact-section-2-img" src="images/ContactUs/mail.png" />
                                    </div>
                                    <div className="col-10">
                                        <h4>Email Us</h4>
                                        <address>
                                            Info@marineworldzone.org
                                        </address>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 text-left">
                                <div className="row contact-section-2-row-bg">
                                    <div className="col-2">
                                        <img className="contact-section-2-img" src="images/ContactUs/telephone.png" />
                                    </div>
                                    <div className="col-10">
                                        <h4>Call Us</h4>
                                        <address>
                                            +91 7985777485 <br/>
                                            +91 9140955965
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUs;
import React from 'react'
import { NavLink } from 'react-router-dom';


function Footer(){
    return(
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <div className="row mt-5">
                            <div className="col-12"><img className="logo fl-left" alt="Marine World Zone" src="logo.jpg" /></div>
                        </div>        
                    </div>
                    <div className='col-12 col-sm-4'>
                        <h4 className='mt-5'>Quick Links</h4>
                        <p><NavLink to='/' className="nav-link">Home</NavLink></p>
                        <p><NavLink to='/portfolio' className="nav-link">Portfolio</NavLink></p>
                        <p><NavLink to='/testimonials' className="nav-link">Testimonials</NavLink></p>
                        <p><NavLink to='/contact' className="nav-link">Contact</NavLink></p>
                        <p><NavLink to='/about' className="nav-link">About</NavLink></p>
                    </div>
                    <div className="col-12 col-sm-4 text-white">
                        <div className='footer-left'>
                        <h4 className='mt-5'>Contact Us</h4>
                        <address className="nav-link">
                            plot no 29, Hardoi road ,<br />
                            DURGAGANJ,<br/> Lucknow . 227101.
                        </address>
                        <i className="fa fa-phone footer-txt-2"></i>: +91 7985777485<br />
		                <i className="fa fa-fax footer-txt-2"></i>: +91 9140955965<br />
		                <i className="fa fa-envelope nav-link">Info@marineworldzone.org</i>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-5 mb-3">             
                <div className="col-auto text-white">
                    <p className="footer-txt-2">© Copyright 2022, All rights reserved</p>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Footer;
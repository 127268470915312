import React,{Component} from "react";
import {Route,Routes} from 'react-router-dom'
import Header from "./HeaderComponent";
import Home from "./HomeComponent";
import Footer from "./FooterComponent";
import About from "./AboutComponent";
import ContactUs from "./ContactComponent";
import Portfolio from "./PortfolioComponent";
import Testimonials from "./ReviewComponent";
import ChatBot from "./chatBot";

class Main extends Component{
    render(){
        return(
            <div>
                <Header/>
                <ChatBot />
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<ContactUs />} />
                    <Route path='portfolio' element={<Portfolio/>} />
                    <Route path='testimonials' element={<Testimonials />} />
                </Routes>
                <Footer />
            </div>
        );
    }
}

export default Main;
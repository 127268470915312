import React,{useEffect} from "react";
import Carousel,{consts} from 'react-elastic-carousel'
import { Button } from "reactstrap";


function Portfolio(){
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const livingRoom=[
        {
            id:1,
            img:"images/Portfolio/living_room/img1.jpg",
            name:"image 1"
        },
        {
            id:2,
            img:"images/Portfolio/living_room/img2.jpg",
            name:"image 2"
        },
        {
            id:3,
            img:"images/Portfolio/living_room/img3.jpg",
            name:"image 3"
        },
        {
            id:4,
            img:"images/Portfolio/living_room/img4.jpg",
            name:"image 4"
        },
        {
            id:5,
            img:"images/Portfolio/living_room/img5.jpg",
            name:"image 5"
        },
        {
            id:6,
            img:"images/Portfolio/living_room/img6.jpg",
            name:"image 6"
        }
    ]
    const kitchen=[
        {
            id:1,
            img:"images/Portfolio/kitchen/img1.jpg",
            name:"image 1"
        },
        {
            id:2,
            img:"images/Portfolio/kitchen/img2.jpg",
            name:"image 2"
        },
        {
            id:3,
            img:"images/Portfolio/kitchen/img3.jpg",
            name:"image 3"
        },
        {
            id:4,
            img:"images/Portfolio/kitchen/img4.jpg",
            name:"image 4"
        },
        {
            id:5,
            img:"images/Portfolio/kitchen/img5.jpg",
            name:"image 5"
        },
        {
            id:6,
            img:"images/Portfolio/kitchen/img6.jpg",
            name:"image 6"
        }
    ]

    const office=[
        {
            id:1,
            img:"images/Portfolio/office/img1.jpg",
            name:"image 1"
        },
        {
            id:2,
            img:"images/Portfolio/office/img2.jpg",
            name:"image 2"
        },
        {
            id:3,
            img:"images/Portfolio/office/img3.jpg",
            name:"image 3"
        },
        {
            id:4,
            img:"images/Portfolio/office/img4.jpg",
            name:"image 4"
        },
        {
            id:5,
            img:"images/Portfolio/office/img5.jpg",
            name:"image 5"
        },
        {
            id:6,
            img:"images/Portfolio/office/img6.jpg",
            name:"image 6"
        }
    ]
    const bedroom=[
        {
            id:1,
            img:"images/Portfolio/bedroom/img1.jpg",
            name:"image 1"
        },
        {
            id:2,
            img:"images/Portfolio/bedroom/img2.jpg",
            name:"image 2"
        },
        {
            id:3,
            img:"images/Portfolio/bedroom/img3.jpg",
            name:"image 3"
        },
        {
            id:4,
            img:"images/Portfolio/bedroom/img4.jpg",
            name:"image 4"
        },
        {
            id:5,
            img:"images/Portfolio/bedroom/img5.jpg",
            name:"image 5"
        },
        {
            id:6,
            img:"images/Portfolio/bedroom/img6.jpg",
            name:"image 6"
        }
    ]

    const breakPoints = [
        { width: 1, itemsToShow: 1,itemPadding:[50, 30] },
        { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false,itemPadding:[50, 30] },
        { width: 850, itemsToShow: 3, itemPadding:[10, 30]   }
    ]
    const myArrow=({ type, onClick, isEdge }) =>{
        const pointer = type === consts.PREV ? <div className="carousel-img-div"><img src="images/Portfolio/previous.png" className="fa-carousel-arrow"/></div> : <div className="carousel-img-div"><img src="images/Portfolio/next.png" className="fa-carousel-arrow"/></div>
        return (
            <Button className="carousel-btn" onClick={onClick} disabled={isEdge}>
                {pointer}
            </Button>
        )
      }
    const render_carousel=(data)=>{
        const returncard= data.map((data)=>{
            return(
                <div key={data.id} className="portfolio-3-div">
                    <img alt={data.name} className="portfolio-3-img-div" src={data.img} />
                </div>
            );
        })
        return(
            <div>
                <Carousel breakPoints={breakPoints} renderArrow={myArrow}
                    renderPagination={({ pages, activePage, onClick }) =>{return(<div hidden></div>)}}
                >
                    {returncard}
                </Carousel>
            </div>
        );
    }
    return(
        <div>
            <div className="portfolio-bg overflow-hidden">
                <div className="contact-test-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 about-top-margin">
                            <div><p className="about-top-txt-1"></p><br/></div>
                            <div><p className="about-top-txt-2">Projects</p><br/></div>
                            <div><strong className="about-top-txt-3">Solution for every space related problems</strong><br/></div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="section-2-bg">
                <div className="container">
                    <div className="row living-div">
                        <div className="col-12 text-center mt-5">
                            <p className="living-text">Living Room</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {render_carousel(livingRoom)}
                        </div>
                    </div>
                    <div className="row living-div">
                        <div className="col-12 text-center mt-5">
                            <p className="living-text">Kitchen</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {render_carousel(kitchen)}
                        </div>
                    </div>
                    <div className="row living-div">
                        <div className="col-12 text-center mt-5">
                            <p className="living-text">Office Space</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {render_carousel(office)}
                        </div>
                    </div>
                    <div className="row living-div">
                        <div className="col-12 text-center mt-5">
                            <p className="living-text">Bedroom</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {render_carousel(bedroom)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;
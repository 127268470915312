import React,{Component} from 'react'
import {Collapse, Nav, NavItem, Navbar,NavbarToggler} from 'reactstrap';
import { NavLink } from 'react-router-dom';


class Header extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            navbar_bg: false,
            nav_collapse:false
        }
        this.changeNavbarBg=this.changeNavbarBg.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.changeNavCollapse = this.changeNavCollapse.bind(this);
    }
    componentDidMount(){
        if(window.innerWidth<=767){
            this.setState({
                nav_collapse: true
            })
        }
        if(window.screenY>300){
            this.setState({
                navbar_bg:true
            })
        }
        window.addEventListener('scroll',this.changeNavbarBg);
        window.addEventListener('resize',this.changeNavCollapse);
    }
    changeNavCollapse(){
        if(window.innerWidth<=767){
            this.setState({
                nav_collapse: true
            })
        }
        else{
            this.setState({
                nav_collapse: false
            })
        }
    }
    changeNavbarBg(){
        if(window.scrollY <= 300){
            this.setState({
                navbar_bg: false
            })
        }
        else{
            this.setState({
                navbar_bg: true
            })
        }
        if(this.state.isNavOpen){
            this.setState({
                isNavOpen:false
            })
        }
    }

    toggleNav() {
        if(window.innerWidth<=767){
            if(this.state.isNavOpen){
                this.setState({
                    isNavOpen:false,
                    navbar_bg:false
                })
                if(window.scrollY <= 300){
                    this.setState({
                        navbar_bg:false
                    })
                }
                else{
                    this.setState({
                        navbar_bg:true
                    })
                }
            }
            else{
                this.setState({
                    isNavOpen:true,
                    navbar_bg: true
                })
            }
        }
      }
    render(){
        return(
            <div>
                <Navbar className={this.state.navbar_bg ? 'nav-bg-active nav-box':'nav-bg'} fixed='top' expand="md">
                    <div className='container'>
                        <NavbarToggler className='toggle-bg' onClick={this.toggleNav}><i hidden={(this.state.isNavOpen) ? 'hidden':''} className="fa fa-bars"></i><span hidden={this.state.isNavOpen ? '':'hidden'}>X</span></NavbarToggler>
                        <div className="mr-auto nav-brand">
                                <p>Marine World Zone</p>
                                <p className='nav-brand-txt'>---------An Interior & Exterior unit---------</p>
                        </div>
                        <Collapse className={(this.state.nav_collapse) ? "nav-collapse":"fl-right"} navbar isOpen={this.state.isNavOpen}>
                            <Nav className='mt-2' navbar>
                                <NavItem className={(this.state.nav_collapse) ? 'nav-item-1':'nav-item'}>
                                    <NavLink onClick={this.toggleNav} to="/" className="nav-link nav-fnt">Home</NavLink>
                                </NavItem>
                                <NavItem className='nav-item'>
                                    <NavLink onClick={this.toggleNav} to='/portfolio' className="nav-link nav-fnt">Portfolio</NavLink>
                                </NavItem>
                                <NavItem className='nav-item'>
                                    <NavLink onClick={this.toggleNav} to='/testimonials' className="nav-link nav-fnt">Testimonials</NavLink>
                                </NavItem>
                                <NavItem className='nav-item'>
                                    <NavLink onClick={this.toggleNav} to="/contact" className="nav-link nav-fnt">Contact</NavLink>
                                </NavItem>
                                <NavItem className='nav-item'>
                                    <NavLink onClick={this.toggleNav} to="/about" className="nav-link nav-fnt">About</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
        );
    }
}

export default Header;
import React,{useEffect} from "react";
import { Button } from "reactstrap";


function About(){
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <div>
            <div className="about-bg overflow-hidden">
                <div className="about-test-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 about-top-margin">
                            <div><p className="about-top-txt-1">FEW WORDS ABOUT US</p><br/></div>
                            <div><p className="about-top-txt-2">About Us</p><br/></div>
                            <div><strong className="about-top-txt-3">OUR INTERIOR DESIGNING STORY</strong><br/></div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="section-2-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-7 about-mr-tp">
                        <h4 className="text-left about-content-txt-1">Marine World Zone</h4>
                        <p className="text-left about-content-txt-2">“We are enhancing more premium & economic modern Interior designing & Turnkey services. We Sandeep kumar and Amit Kumar who came together under one roof for outstanding delivery to achieve higher end results in Interior Designs. Our services are truly based on Interior Designs & its execution. Our excellent working process executes a constructive path to take your project on a different level in functional design.<br/><p className="mt-4">We create breathtaking, magnificent and outstanding views to your dream house. Our vast experience in the interiors industry has helped us form partnerships with a well trusted and reputed suppliers who help us to optimize budget for our interiors designs.</p></p>
                        <div className="text-left">
                            <p className="mt-5 about-social-txt">Get in Touch</p>
                            <div><a href="#"><span><img className="about-social-img" src="images/AboutUs/facebook.png" /></span></a><a href="#"><span><img className="about-social-img" src="images/AboutUs/instagram.png" /></span></a></div>
                        </div>
                    </div>
                    {/*<div className="col-12 col-lg-5 about-mr-tp">
                        <img className="about-img" src="images/AboutUs/about_img.jpg" alt="Home designs" />
                    </div>*/}
                    <div className="col-12 col-lg-5 about-mr-tp">
                        <div className="row">
                            <h2>Our Founder's</h2>
                        </div>
                        <div className="row mt-3">
                            <img className="mwz-img" src="mwz_1.jpg" />
                            <h5 className="mt-1">Sandeep Kumar</h5>
                        </div>
                        <div className="row mt-3">
                            <img className="mwz-img" src="mwz_2.jpg" />
                            <h5 className="mt-1">Amit Kumar</h5>
                        </div>
                    </div>
                </div>
                <div className="row footer-mr">
                    <div className="col-12 col-sm-4">
                        <div className="about-footer-box">
                            <img className="about-footer-img" src="images/AboutUs/img1.png" />
                            <p className="about-footer-box-1">40+</p>
                            <p className="about-footer-box-2">Project Completed</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="about-footer-box">
                            <img className="about-footer-img" src="images/AboutUs/img2.png" />
                            <p className="about-footer-box-1">100+</p>
                            <p className="about-footer-box-2">Happy Customers</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="about-footer-box">
                            <img className="about-footer-img" src="images/AboutUs/img4.png" />
                            <p className="about-footer-box-1">7</p>
                            <p className="about-footer-box-2">Years in Service</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default About;
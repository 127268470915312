import React,{useEffect} from "react";
import TawkTo from "tawkto-react";


function ChatBot(){
    useEffect(() => {
    
        var tawk = new TawkTo("64489abd4247f20fefedce68", "1gutpotjj")
    
        tawk.onStatusChange((status) => 
        {
            console.log(status);
        })
    
    }, [])
    return null;
}

export default ChatBot;